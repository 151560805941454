// Firebase and Functions
import { app } from "./FirebaseApp.js";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Constants
import { DEFAULT_FUNCTIONS_EMULATOR_HOST, DEFAULT_FUNCTIONS_EMULATOR_PORT } from '../shared/constants/emulator.js';


let functionsBackend = getFunctions(app, 'us-west2');
// Fallback if the user forgets to call useEmulator() when hosting locally.
if (process.env.NODE_ENV !== "production") {
    console.log(`Using Functions Emulator`);
    useFunctionsEmulator();
}

/**
 * Retrieve instance of Firebase functions loaded to work for given dev
 * or production environment.
 * @returns {Functions} Instance of Firebase Functions
 */
export function getFunctionsBackend() {
    return functionsBackend;
}

/**
 * Sets up the app to use an emulator for functions operations
 * @returns {Promise<undefined>}
 */
export async function useFunctionsEmulator(host=DEFAULT_FUNCTIONS_EMULATOR_HOST, port=DEFAULT_FUNCTIONS_EMULATOR_PORT) {
    connectFunctionsEmulator(functionsBackend, host, port);
}



