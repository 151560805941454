
// Firebase & Services
import { getAuthUid, getAuthObject } from "./FirebaseAuth.js";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getDatabase } from "./FirebaseFirestore.js";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore/lite";
import { getFunctionsBackend } from "./FirebaseFunctions.js";
import { httpsCallable } from "firebase/functions";

// Classes
import User, { userConverter } from "../shared/classes/User.js";
import ContactInfo, { contactInfoConverter } from "../shared/classes/ContactInfo.js";
import { functionIDs } from "../shared/constants/functions.js";
import { UserCollection } from "../shared/constants/database.js";




/**
 * Creates a new user with the given id and attributes.
 * To initialize a completely new user, it's sufficient to call "new User()".
 * @param {User} user
 * @returns {Promise<null>}
 */
export async function createUserDocument(user) {
    let [uid, db] = await Promise.all([getAuthUid(), getDatabase()]);
    if (uid === null || uid === undefined) {
        throw new Error("No user is currently logged in");
    }

    let userReference = doc(db, UserCollection, user.userId).withConverter(userConverter);
    
    userReference = await setDoc(userReference, user);
    
    return;
}

/**
 * Returns the User object for the given id.
 * If no such user exists, the promise rejects.
 * @returns {Promise<User>}
 */
 export async function getUser(userId) {
    let db = await getDatabase();

    let userReference = doc(db, UserCollection, userId).withConverter(userConverter);
    let userDoc = await getDoc(userReference);

    if (userDoc.exists()) {
        return userDoc.data();
    } else {
        throw new Error(`Not-Found: Document ${userId} was not found in the user collection.`);
    }
}

export async function getLoggedInUser() {
    let userId = await getAuthUid();
    if (userId)
        return await getUser(userId);
    else
        return null;
}

export let getLoggedInUserId = getAuthUid;


export async function getUserContactInfo() {
    let user = await getLoggedInUser();
    return new ContactInfo(user.displayName, user.phoneNumber, user.email);
}

/**
 * Update the logged-in user's contact information.
 * @param {ContactInfo} contactInfo 
 * @returns {Promise<null>}
 */
export async function updateUserContactInfo(contactInfo) {
    let [userId, db] = await Promise.all([getAuthUid(), getDatabase()]);

    let userReference = doc(db, UserCollection, userId);

    await updateDoc(userReference, contactInfoConverter.toFirestore(contactInfo));
    
    return;
}

/**
 * Log a user in using Email and Password for Google Auth. Only to be used 
 * for testing purposes.
 * @param {string} username 
 * @param {string} password 
 * @returns 
 */
export async function login(username, password) {
    let auth = await getAuthObject();
    return await signInWithEmailAndPassword(auth, username, password);
}

/**
 * Log the user out of the system and Firebase auth. For use in development
 * and production.
 * @returns 
 */
export async function logout() {
    let auth = await getAuthObject();
    return await signOut(auth);
}

/**
 * Relay the call to initialize a new customer account using the provided
 * userId. Call goes to the backend Function: initializeNewCustomer.
 * @param {string} userId 
 * @returns 
 */
 export async function initializeNewCustomer(userId = null) {
    userId ??= await getAuthUid();
    let func = httpsCallable(getFunctionsBackend(), functionIDs.users.initializeNewCustomer);
    return await func(userId);
}

