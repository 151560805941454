
/** @type {number} The minimum number of hours that must elapse between an user purchasing coverage and that coverage becoming active. */
export const MIN_COVERAGE_START_DELAY = 48;
/** @type {number} The minimum number of days a coverage must last. */
export const MIN_COVERAGE_LENGTH_DAYS = 1;
/** @type {number} The minimum number of hours a coverage must last. */
export const MIN_COVERAGE_LENGTH_HOURS = 24;
/** @type {number} The price in dollars to cover a single vehice for a day. */
export const PRICE_PER_VEHICLE_DAY = 50;
/** @type {number} The price to purchase a season pass for a single vehicle */
export const PRICE_SEASON_PASS = 300;
/** @type {number} The maximum number of months out for which a customer can purchase coverage. */
export const MAX_MONTHS_BEFORE_COVERAGE_START = 7;
/** @type {number} The processing fee percentage that we are charging our customers for Square. */
export const PROCESSING_FEE = 0.029;

export const SEASON_MONTH_START = 11; // November
export const SEASON_DAY_START = 6; // 6th of the month
export const SEASON_MONTH_END = 2; // February
export const SEASON_DAY_END = 24; // 24th of the month