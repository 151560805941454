

// Emulator URLs
export const DEFAULT_HOSTING_EMULATOR_URL = 'http://localhost:5000';
export const DEFAULT_FIRESTORE_EMULATOR_HOST = "localhost";
export const DEFAULT_FIRESTORE_EMULATOR_PORT = 8080;
export const DEFAULT_FIRESTORE_EMULATOR_URL = "http://localhost:8080";
export const DEFAULT_AUTH_EMULATOR_URL = "http://localhost:9099";
export const DEFAULT_FUNCTIONS_EMULATOR_HOST = "localhost";
export const DEFAULT_FUNCTIONS_EMULATOR_PORT = 5001;
export const DEFAULT_FUNCTIONS_EMULATOR_URL = `${DEFAULT_FUNCTIONS_EMULATOR_HOST}:${DEFAULT_FUNCTIONS_EMULATOR_PORT}`;
export const DEFAULT_EMULATOR_HUB_URL = 'http://localhost:4400';
export const EMULATOR_PROJECT_ID = "dune-recovery";