// Classes
import Vehicle, { vehicleConverter } from "./Vehicle.js";
import Coverage, { coverageConverter } from "./Coverage.js";

/**
 * Class representing a user's account information.
 */
export default class User {

    /**
     * Create a user object.
     * @param {string} userId 
     * @param {Vehicle[]} activeVehicles 
     * @param {Vehicle[]} inactiveVehicles
     * @param {Coverage[]} activeCoverage 
     * @param {Coverage[]} lapsedCoverage 
     * @param {string} displayName
     * @param {string} phoneNumber
     * @param {string} email
     */
    constructor(userId, activeVehicles=[], inactiveVehicles=[], activeCoverage=[], 
            lapsedCoverage=[], displayName="", phoneNumber="", email="") {
        
        this.userId = userId;
        this.activeVehicles = activeVehicles;
        this.inactiveVehicles = inactiveVehicles;
        this.activeCoverage = activeCoverage;
        this.lapsedCoverage = lapsedCoverage;
        this.displayName = displayName;
        this.phoneNumber = phoneNumber;
        this.email = email;
    }

}



/**
 * Map object that converts User objects to Firestore docs and vice versa.
 */
export let userConverter = {
    /** 
     * Convert user to object that can be stored in Firestore
     * @param {User} user 
     * @return {Map}
     */
    toFirestore: function(user) {
        return {
            activeVehicles: user.activeVehicles.map(vehicleConverter.toFirestore),
            inactiveVehicles: user.inactiveVehicles.map(vehicleConverter.toFirestore),
            activeCoverage: user.activeCoverage.map(coverageConverter.toFirestore),
            lapsedCoverage: user.lapsedCoverage.map(coverageConverter.toFirestore),
            displayName: user.displayName,
            phoneNumber: user.phoneNumber,
            email: user.email
        }
    },
    /** 
     * Convert Firestore document to User object that can be used in the app.
     * @param {DocumentSnapshot} snapshot Snapshot of the queried document from 
     * Firestore.
     * @param {SnapshotOptions} options
     * @return {User}
     */
    fromFirestore: function(snapshot, options) {
        let data = snapshot.data(options);
        return new User(
            snapshot.id,
            data.activeVehicles.map((obj) => {
                obj.userId = snapshot.id; 
                return Vehicle.hydrate(obj)
            }),
            data.inactiveVehicles.map((obj) => {
                obj.userId = snapshot.id; 
                return Vehicle.hydrate(obj)
            }),
            data.activeCoverage.map((obj) => {
                obj.userId = snapshot.id; 
                return Coverage.parseAndHydrate(obj)
            }),
            data.lapsedCoverage.map((obj) => {
                obj.userId = snapshot.id; 
                return Coverage.parseAndHydrate(obj)
            }),
            data.displayName,
            data.phoneNumber,
            data.email
        )
    }
}