
/**
 * Enum encoding for the different days of the week to numbers used
 * by the Javascript DateTime class.
 * @enum {number}
 */
 export default Object.freeze({
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6
});