import CoverageTypes from "../enums/CoverageTypes.js";
import Days from "../enums/Days.js";
import { findNumberDays, doesDateFallWithin } from "../utils/DateTime.js";
import { PRICE_PER_VEHICLE_DAY, PRICE_SEASON_PASS } 
        from "../constants/coverage.js";

/** 
 * Class representing an instance of a vehicle coverage policy.
 */
export default class Coverage {

    /**
     * Create a Coverage object.
     * @param {string} userId 
     * @param {string} purchaseId 
     * @param {CoverageTypes} type 
     * @param {string[]} vehicleIds 
     * @param {Date} startTime 
     * @param {Date} endTime 
     */
    constructor(userId=null, purchaseId=null, type=CoverageTypes.DAILY, 
                vehicleIds=[], startTime=null, endTime=null) {
        
        this.userId = userId;
        this.purchaseId = purchaseId;
        this.type = type;
        this.vehicleIds = vehicleIds;
        this.startTime = startTime;
        this.endTime = endTime;
    }

    /**
     * Calculate the cost of the coverage policy for the type of coverage 
     * and number of vehicles.
     * @returns {number}
     */
    calculateCost() {
        // Find Price of the Date Range per vehicle
        let unitPrice;
        if (this.type == CoverageTypes.DAILY) {
            let days = findNumberDays(this.startTime, this.endTime);
            unitPrice = days * PRICE_PER_VEHICLE_DAY;
        } else {
            unitPrice = PRICE_SEASON_PASS;
        }

        // Multiply by number of vehicles
        return (unitPrice * this.vehicleIds.length);
    }

    /**
     * Coverage object method can determine whether a date object falls within
     * its coverage ability.
     * @param {Date} date 
     * @returns boolean
     */
    isDateCovered(date) {

        // If not in range at all, return false
        if (!doesDateFallWithin(date, this.startTime, this.endTime)) {
            return false;
        }

        // Only check subrange for Fixed coverage types
        if (this.type == CoverageTypes.FIXED) {
            return isDateInSeasonSubRange(date)
        } 

        return true;
    }

    /**
     * Convert a map object containing all the properties of the Coverage
     * class into a Coverage Object.
     * @param {Map} data 
     * @returns {Coverage}
     */
    static hydrate(data) {
        return new Coverage(
            data.userId,
            data.purchaseId,
            data.type,
            data.vehicleIds,
            data.startTime,
            data.endTime
        )
    }

    /**
     * Convert a map object containing all the properties of the Coverage
     * class into a Coverage Object. This differs from hydrate in that the
     * time properties in data are Firestore.Timestamp values. These timestamps
     * can't be used in our app, they need to be parse (converted to Date 
     * objects) before we hydrate the Coverage object.
     * @param {Map} data Time values must be of type Firestore.Timestamp or an 
     * error will be thrown.
     * @returns {Coverage}
     */
    static parseAndHydrate(data) {
        data.startTime = data.startTime.toDate();
        data.endTime = data.endTime.toDate();
        return Coverage.hydrate(data);
    }
}

/**
 * Map object that converts Coverage objects to Firestore docs and vice versa.
 */
export let coverageConverter = {
    /** 
     * Convert user to object that can be stored in Firestore
     * @param {Coverage} coverage 
     * @return {Map}
     */
    toFirestore: function(coverage) {
        return {
            userId: coverage.userId,
            purchaseId: coverage.purchaseId,
            type: coverage.type,
            vehicleIds: coverage.vehicleIds,
            startTime: coverage.startTime,
            endTime: coverage.endTime
        }
    },
    /** 
     * Convert Firestore document to Coverage object that can be used in the
     * app.
     * @param {DocumentSnapshot} snapshot Snapshot of the queried document 
     * from Firestore.
     * @param {SnapshotOptions} options
     * @return {Coverage}
     */
    fromFirestore: function(snapshot, options) {
        let data = snapshot.data(options);
        return Coverage.parseAndHydrate(data);
    }
}



let Season2022SubRange = new Set([Days.FRIDAY, Days.SATURDAY, Days.SUNDAY]);
/**
 * 
 * @param {Date} date
 * Javascript Days: 0 (Sun) - 6 (Sat)
 */
export function isDateInSeasonSubRange(date) {
    return Season2022SubRange.has(date.getDay());
}