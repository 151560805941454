

/**
 * Firestore Collection Names
 */
export const RescueRequestCollection = "rescue-requests";
export const CoveragePurchaseCollection = "purchases";
export const OperatorCollection = "operators";
export const UserCollection = "users";
export const CoverageItemsCollection = "items";
export const PolicyCollection = "policy";
export const PromoCodeCollection = "promo-codes";