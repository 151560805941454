import { app } from "./FirebaseApp.js";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore/lite";

import { DEFAULT_FIRESTORE_EMULATOR_HOST, DEFAULT_FIRESTORE_EMULATOR_PORT } from '../shared/constants/emulator.js';


let firestoreDB = getFirestore(app);

if (process.env.NODE_ENV !== "production") {
    console.log(`Using Firestore Emulator`);

    useFirestoreEmulator(firestoreDB);
}


/**
 * Returns the firestore database object for the current Firebase app
 * @returns {Promise<firebase.firestore.Firestore>}
 */
async function getDatabase() {
    return firestoreDB;
}

/**
 * Sets up the app to use an emulator for firestore operations.
 * @returns {Promise<undefined>}
 */
function useFirestoreEmulator(db, host=DEFAULT_FIRESTORE_EMULATOR_HOST, port=DEFAULT_FIRESTORE_EMULATOR_PORT) {
    try {
        connectFirestoreEmulator(db, host, port);
    } catch(error) {
        console.debug(error);
    }
    
    return;
}


export { getDatabase , useFirestoreEmulator }