import { logout } from "../../data-access/UserServices.js";
import { getAuthUser } from "../../data-access/FirebaseAuth.js";

export async function setupNavBar(navbarEl) {
    let user = await getAuthUser();
    navbarEl.addEventListener('logout', () => {
        logout();
        window.location = "./index.html";
    });
    navbarEl.user = user;
}