/**
 * The object containing the user's custom claims/permissions. Each key is a value from the PERMISSIONS enum, and each value is a boolean indicating whether that permission is granted.
 * @typedef {Object} CustomUserClaims
 * @property {boolean} customer - Whether the user should have access to standard customer functionality (uploading vehicle data, purchasing coverage, etc.)
 * @property {boolean} operator - Whether the user should have access to operator functionality (viewing and responding to distress calls)
 * @property {boolean} admin - Whether the user should have admin access (adding, editing, and deleting private user data and other administrative tasks)
 */

/**
 * A type of permission that can be granted to a user, giving them access to different pages and features in the app.
 * @typedef {(PERMISSIONS.CUSTOMER | PERMISSIONS.OPERATOR | PERMISSIONS.ADMIN)} Permission
 */

/**
 * @enum {string}
 */
export default Object.freeze({
    CUSTOMER: "customer",
    OPERATOR: "operator",
    ADMIN: "admin",
});