/**
 * Enum encoding for the different types of coverage purchases we make
 * available.
 * @enum {number}
 */
export default Object.freeze({
    /** Customer chooses selection based on daily */
    DAILY: "daily",
    /** Coverage dates are fixed, such as a season pass */
    FIXED: "fixed"
});
