import VNTs from "../enums/VehicleNumberTypes.js";

/**
 * Represents a vehicle owned by a given user.
 */
export default class Vehicle {

    /**
     * Create a vehicle Object
     * @param {string} nickname 
     * @param {string} id 
     * @param {string} userId 
     * @param {string} vehicleNumber 
     * @param {VehicleNumberTypes} vehicleNumberType 
     * @param {string} ownerName
     * @param {string} make
     * @param {string} model
     */
    constructor(nickname="", id=null, userId=null, vehicleNumber="", 
            vehicleNumberType=VNTs.VIN, ownerName="", make="", model="") {
        /**
         * The nickname the owning user has given to the vehicle. Used to quickly identify a vehicle within the app.
         * @type {string}
         */
        this.nickname = nickname;
        /**
         * The unique id identifying a given vehicle within the database.
         * @type {string}
         */
        this.id = id;
        /**
         * The user id of the user which owns this vehicle.
         * @type {number}
         */
        this.userId = userId;
        /**
         * An id that operators use to confirm a vehicle's identity during pickup. The user should supply some type of vehicle number for every vehicle they register.
         * @type {string}
         */
        this.vehicleNumber = vehicleNumber;
        /**
         * The type of id used to identify the vehicle. For example, users can register a vehicle with a vin number, engine code, etc.
         * @type {VehicleNumberTypes}
         */
        this.vehicleNumberType = vehicleNumberType;
        /**
         * @type {string}
         */
        this.ownerName = ownerName;
        /**
         * @type {string}
         */
        this.make = make;
        /**
         * @type {string}
         */
        this.model = model;
    }

    /**
     * Get the last 6 characters of the Vehicle Identication Number. 
     * @returns {string}
     */
    get vinLast6Chars() {
        return this.vehicleNumber.slice(-6);
    }

    /**
     * Convert a map object containing all the properties of the Coverage
     * class into a Coverage Object.
     * @param {Map} obj 
     * @returns {Vehicle}
     */
    static hydrate(obj) {
        return new Vehicle(
            obj.nickname,
            obj.id,
            obj.userId,
            obj.vehicleNumber,
            obj.vehicleNumberType,
            obj.ownerName,
            obj.make,
            obj.model
        );
    }

    /**
     * 
     * @param {Vehicle} vehicle 
     */
    static deepCopy(vehicle) {
        return Vehicle.hydrate(vehicle);
    }

    /**
     * Verify if these two vehicles match one another.
     * @returns {Boolean} Returns True if the vehicles match.
     */
    matches(vehicle) {
        return this.nickname === vehicle.nickname
            && this.id === vehicle.id
            && this.userId === vehicle.userId
            && this.vehicleNumber === vehicle.vehicleNumber
            && this.vehicleNumberType === vehicle.vehicleNumberType
            && this.ownerName === vehicle.ownerName
            && this.make === vehicle.make
            && this.model === vehicle.model;
    }
}

/**
 * Find the next available unique vehicle-ID from the vehicles list
 * provided. Typically, this list will be all the vehicles from a 
 * given user and the next available ID will be the max ID in the list
 * plus one.
 * @param {vehicles} Vehicle[] A list of integers representing vehicle ID
 * @returns {maxID} (Integer) The next available ID for the provided list. 
 */
export function findNextAvailableID(vehicles) {
    let maxID = -1;
    for (const vehicle of vehicles) {
        let vid = Number(vehicle.id);
        if (vid >= maxID) {
            maxID = vid;
        }
    }
    return maxID + 1;
}

/**
 * Find the vehicle in the provided array with the matching vehicle ID.
 * @param vehicleID {string} The potential ID of a vehicle in the array.
 * @returns {Vehicle} Returns null if no matching vehicle was found.
 */
export function findVehicleByID(vehicleID, vehicles) {
    for (const vehicle of vehicles) {
        if (vehicle.id == vehicleID) {
            return vehicle;
        }
    }
    return null;
} 

/**
 * Map object that converts Vehicle objects to Firestore docs and vice versa.
 */
export let vehicleConverter = {
    /** 
     * Convert vehicle to map that can be stored in Firestore
     * @param {Vehicle} vehicle 
     * @return {Map}
     */
    toFirestore: function(vehicle) {
        return {
            nickname: vehicle.nickname,
            id: vehicle.id,
            vehicleNumber: vehicle.vehicleNumber,
            vehicleNumberType: vehicle.vehicleNumberType,
            ownerName: vehicle.ownerName,
            make: vehicle.make,
            model: vehicle.model
        }
    },
    /** 
     * Convert Firestore document to Vehicle object that can be used in the
     * app.
     * @param {DocumentSnapshot} snapshot Snapshot of the queried document 
     * from Firestore.
     * @param {SnapshotOptions} options
     * @return {Vehicle}
     */
    fromFirestore: function(snapshot, options) {
        let data = snapshot.data(options);
        data.userId = snapshot.id;
        return Vehicle.hydrate(data);
    }
}
