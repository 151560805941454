import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth';
import { app } from "./FirebaseApp.js";

import { DEFAULT_AUTH_EMULATOR_URL } from "../shared/constants/emulator.js";

// Initialize Auth
let auth = getAuth(app);
if (process.env.NODE_ENV !== "production") {
    console.log(`Using Auth Emulator`);
    connectAuthEmulator(auth, DEFAULT_AUTH_EMULATOR_URL);
}


let user = undefined;
let token = undefined;
let claims = undefined;
let authInitialized = new Promise((resolve) => {
    onAuthStateChanged(auth, async (loggedInUser) => {
        user = loggedInUser;
        token = await user?.getIdTokenResult();
        claims = token?.claims ?? {};
        resolve(true);
    });
})

/**
 * Returns the current user's id if a user is logged in. Otherwise, returns null.
 * @returns {Promise<string | null>}
 */
export async function getAuthUid() {
    await authInitialized;
    return user?.uid;
}

/**
 * Returns the current user if a user is logged in. Otherwise, returns null.
 * @returns {Promise<Auth.User | null>}
 */
 export async function getAuthUser() {
    await authInitialized;
    return user;
}

/**
 * Returns the auth object for current Firebase app
 * @returns {Promise<firebase.auth.Auth>}
 */
export async function getAuthObject() {
    await authInitialized;
    return auth;
}

export async function getAuthUserToken() {
    await authInitialized;
    return token;
}

export async function getAuthClaims() {
    await authInitialized;
    return claims;
}

/**
 * Sets up the app to use an emulator for authentication
 * @param {string} url - The url at which the auth emulator is located.
 */
export async function useAuthEmulator(auth, url=DEFAULT_AUTH_EMULATOR_URL) {
    connectAuthEmulator(auth, url);
}