
// Time Conversions from Milliseconds
const millisecondsPerSecond = 1000;
const millisecondsPerMinute = 60 * millisecondsPerSecond;
const millisecondsPerHour = 60 * millisecondsPerMinute;
const millisecondsPerDay = 24 * millisecondsPerHour;
const millisecondsPerWeek = 7 * millisecondsPerDay;
const millisecondsPerMonth = 30 * millisecondsPerDay;

/**
 * Create a javascript Date object using the date and time strings
 * provided.
 * @param {string} date In the format of 'YYYY-MM-DD'
 * @param {string} time In the format of 'HH:MM' using 24 hour clock.
 * @returns {Date}
 */
export function createDateTime(date, time) {
    let dtString = date + 'T' + time + ':00';
    let dateTime = new Date(dtString);
    return dateTime;
}

/**
 * Calculate the number of hours inside the date range from start to end. This
 * method assumes that start comes before end, and it rounds down to find elapsed
 * hours.
 * @param {Date} start 
 * @param {Date} end 
 * @returns {number} Number of hours.
 */
export function findNumberHours(start, end) {
    return Math.round((end.getTime() - start.getTime()) / millisecondsPerHour);
}

/**
 * Calculate the number of days inside the date range from start to end. This
 * method assumes that start comes before end, and it rounds down to find elapsed
 * hours.
 * @param {Date} start 
 * @param {Date} end 
 * @returns {number} Number of days.
 */
 export function findNumberDays(start, end) {
    return Math.round((end.getTime() - start.getTime()) / millisecondsPerDay);
}

/**
 * @param {Date} date The starting date time that we want to add minutes to. It will
 * not be modified by this method.
 * @param {number} minutes The number of minutes that we want to add (unit: minutes)
 * @returns {Date} The date equal to the start date plus minutes specified.
 */
 export function addMinutes(date, minutes) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + minutes);
    return newDate;
}

/**
 * @param {Date} date The starting date time that we want to add hours to. It will
 * not be modified by this method.
 * @param {number} hours The number of hours that we want to add (unit: hours)
 * @returns {Date} The date equal to the start date plus hours specified.
 */
export function addHours(date, hours) {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
}

/**
 * @param {Date} date The starting date time that we want to add days to. It will
 * not be modified by this method.
 * @param {number} days The number of days that we want to add (unit: days)
 * @returns {Date} The date equal to the start date plus days specified.
 */
 export function addDays(date, days) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

/**
 * @param {Date} date The starting date time that we want to add months to. It will
 * not be modified by this method.
 * @param {number} months The number of months that we want to add (unit: months)
 * @returns {Date} The date equal to the start date plus months specified.
 */
 export function addMonths(date, months) {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}

/** 
 * Create a valid string for the date in the following format: YYYY-MM-DD
 * @param {Date} date The javascript Date object we'll get info from.
 * @returns {string} YYYY-MM-DD
 */
export function getDateString(date) {
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
}

/**
 * Create a valid string for the time in the following format: HH:MM
 * @param {Date} date The javascript Date object we'll get info from.
 * @returns {string} HH:MM
 */
export function getTimeString(date) {
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
}

export function isValidDateString(date) {
    if (date != null || date != undefined) {
        if (date.length == 10) {
            return true;
        }
    }
    return false;
}

export function isValidTimeString(time) {
    if (time != null || time != undefined) {
        if (time.length == 5) {
            return true;
        }
    }
    return false;
}

/**
 * Convert time from 24 hour clock to 12 hours. Only works for hours
 * and minutes.
 * @param {string} time "HH:MM"
 * @returns {string} "HH:MM pm" 
 */
export function convert24to12(time) {
    const parts = time.split(':');
    let hoursBefore = Number(parts[0]);
    let hoursAfter = hoursBefore % 12;
    if (hoursAfter == 0) {
        hoursAfter = 12;
    }

    let timeString = "";
    if (hoursAfter < 10) {
        timeString += ("0" + hoursAfter.toString());
    } else {
        timeString += hoursAfter.toString();
    }
    timeString += (':' + parts[1]);
    if (hoursBefore < 12) {
        timeString += ' am';
    } else {
        timeString += ' pm';
    }
    return timeString;
}


export function makeDateTimeString(dateTime) {
    let dateFormatOptions = {year: 'numeric', month: 'short', day: 'numeric', 
        hour: 'numeric', minute: 'numeric', timeZoneName: 'short'};
    return dateTime.toLocaleString('en-US', dateFormatOptions);
}

export function makeShortTimeString(dateTime) {
    let dateFormatOptions = {month: 'short', day: 'numeric', 
        hour: 'numeric', minute: 'numeric'};
    
    return dateTime.toLocaleString('en-US', dateFormatOptions);
}

/**
 * Check if the provided date falls between the start and end dates.
 * Function assumes that start comes before end chronologically.
 * @param {Date} date 
 * @param {Date} start 
 * @param {Date} end 
 * @returns {boolean}
 */
export function doesDateFallWithin(date, start, end) {

    return (start <= date) && (date <= end);
}

/**
 * Do the two start-end date pairs have any overlap in their date ranges.
 * @param {Date} startA 
 * @param {Date} endA
 * @param {Date} startB
 * @param {Date} endB
 * @returns {Boolean} True if there is an overlap, false otherwise.
 */
export function hasOverlap(startA, endA, startB, endB) {
    
    return (
        doesDateFallWithin(startA, startB, endB) ||
        doesDateFallWithin(endA, startB, endB) ||
        doesDateFallWithin(startB, startA, endA) ||
        doesDateFallWithin(endB, startA, endA)
    )
}
