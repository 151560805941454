
// Page URLs
export const HOMEPAGE_URL = "/index.html";
export const HOMEPAGE_NAME = "index";
export const SERVICES_URL ="/services.html";
export const SERVICES_NAME = "services";
export const LOGIN_URL = "/login.html?state=sign-in";
export const LOGIN_NAME = "login?state=sign-in";
export const SIGN_UP_URL = "/login.html?state=make-account";
export const SIGN_UP_NAME = "login?state=make-account";
export const DASHBOARD_URL = "/dashboard.html";
export const DASHBOARD_NAME = "dashboard";
export const MANAGE_VEHICLES_URL = "/manage-vehicles.html";
export const MANAGE_VEHICLES_NAME = "manage-vehicles";
export const ADD_VEHICLE_URL = "/add-vehicle.html";
export const ADD_VEHICLE_NAME = "add-vehicle";
export const EDIT_VEHICLE_URL = (vehicleId) => `/edit-vehicle.html?id=${vehicleId}`;
export const EDIT_VEHICLE_NAME = (vehicleId) => `edit-vehicle?id=${vehicleId}`;
export const PURCHASE_COVERAGE_URL = "/purchase-coverage.html";
export const PURCHASE_COVERAGE_NAME = "purchase-coverage";
export const PURCHASE_HISTORY_URL = "/purchase-history.html";
export const PURCHASE_HISTORY_NAME = "purchase-history";
export const REQUEST_RESCUE_URL = "/request-rescue.html";
export const REQUEST_RESCUE_NAME = "request-rescue";
export const VEHICLE_RECOVERY_URL = "/vehicle-recovery.html";
export const VEHICLE_RECOVERY_NAME = "vehicle-recovery";
export const PROFILE_URL = "/profile.html";
export const PROFILE_NAME = "profile";
export const TERMS_SERVICE_URL = "/terms-of-service.html";
export const TERMS_SERVICE_NAME = "terms-of-service";
export const PRIVACY_POLICY_URL = "/privacy-policy.html";
export const PRIVACY_POLICY_NAME = "privacy-policy";
export const GETTING_STARTED_URL = "/getting-started.html";
export const GETTING_STARTED_NAME = "getting-started";
export const ABOUT_US_URL = "/about-us.html";
export const ABOUT_US_NAME = "about-us";
export const CONTACT_US_URL = "/contact-us.html";
export const CONTACT_US_NAME = "contact-us";


// Operator Viewpoint URLs
export const OPERATOR_REQUEST_LIST_URL = "/rescue-requests.html";
export const OPERATOR_REQUEST_LIST_NAME = "rescue-requests";
export const RECOVERY_DETAIL_URL = (requestId) => `/recovery-detail.html?id=${requestId}`;
export const RECOVERY_DETAIL_NAME = (requestId) => `recovery-detail?id=${requestId}`;
export const PROMO_CODE_URL = "/promo-code-generator.html";
export const PROMO_CODE_NAME = "promo-code-generator";

// Adming Viewpoint URLs
export const ADMIN_URL = "/admin.html";
export const ADMIN_NAME = "admin";


// Special URLs (more subject to change)
export const EXISTING_CUSTOMER_LANDING_PAGE_URL = DASHBOARD_URL;
export const EXISTING_CUSTOMER_LANDING_PAGE_NAME = DASHBOARD_NAME;
export const NEW_CUSTOMER_LANDING_PAGE_URL = DASHBOARD_URL;
export const NEW_CUSTOMER_LANDING_PAGE_NAME = DASHBOARD_NAME;

// Permissions Redirect URLs
export const CUSTOMER_REDIRECT_URL = DASHBOARD_URL;
export const CUSTOMER_REDIRECT_NAME = DASHBOARD_NAME;
export const GENERIC_REDIRECT_URL = LOGIN_URL;
export const GENERIC_REDIRECT_NAME = LOGIN_NAME;


