
/**
 * Class representing an the contact info of a user.
 */
export default class ContactInfo {

    /**
     * Create a contact info object.
     * @param {string} displayName 
     * @param {string} phoneNumber 
     * @param {string} email 
     */
    constructor(displayName="", phoneNumber="", email="") {
        this.displayName = displayName;
        this.phoneNumber = phoneNumber;
        this.email = email;
    }

    /**
     * Convert a map object containing all the properties of the ContactInfo
     * class into a ContactInfo Object.
     * @param {Map} data 
     * @returns {ContactInfo}
     */
    static hydrate(obj) {
        return new ContactInfo(
            obj.displayName,
            obj.phoneNumber,
            obj.email
        )
    } 
}


/**
 * Map object that converts ContactInfo objects to Firestore docs 
 * and vice versa.
 */
export let contactInfoConverter = {
    /** 
     * Convert ContactInfo to object that can be stored in Firestore
     * @param {ContactInfo} infoObject 
     * @return {Map}
     */
    toFirestore: function(infoObject) {
        return {
            displayName: infoObject.displayName,
            phoneNumber: infoObject.phoneNumber,
            email: infoObject.email
        }
    },
    /** 
     * Convert Firestore document to ContactInfo object that can be used in the
     * app.
     * @param {DocumentSnapshot} snapshot Snapshot of the queried document 
     * from Firestore.
     * @param {SnapshotOptions} options
     * @return {ContactInfo}
     */
    fromFirestore: function(snapshot, options) {
        let data = snapshot.data(options);
        return new ContactInfo(
            data.displayName,
            data.phoneNumber,
            data.email
        );
    }
}