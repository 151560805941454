//import firebase from 'firebase/compat/app/dist/index.mjs';
import { initializeApp, getApp, getApps } from 'firebase/app';

// Initialize Firebase

const firebaseConfig = {
    projectId: "dune-recovery",
    apiKey: "AIzaSyAZ87QzqZaqVWqrOy0uRUymu8VZ2vx05SU",
    authDomain: "glamis911.com",
    storageBucket: "dune-recovery.appspot.com",
    messagingSenderId: "287951343419",
    appId: "1:287951343419:web:d5ab6e70757f4f8f70b11e",
    measurementId: "G-SLELSNRXZ0"
};

let initializedApps = getApps();

console.log("Initializing App");

initializedApps.forEach(app => {
    console.log(`App v9 ${app.name}: { ${app.options.projectId}, ${app.options.authDomain} }`)
});

export let app = !initializedApps.length ? initializeApp(firebaseConfig) : getApp();

