// Firebase
import { getAuthClaims } from "./FirebaseAuth.js";

// Classes and Constants
import PERMISSIONS from "../shared/enums/Permissions.js";
import { CUSTOMER_REDIRECT_URL, GENERIC_REDIRECT_URL } from "../shared/constants/urls.js";

/**
 * Checks whether the currently logged in user has the given permissions. 
 * If not, redirects the user to a default landing page.
 * @param {Array<Permission>} requiredPermissions - The list of permissions checked against the user's custom claims.
 */
export async function requirePermissions(...requiredPermissions) {
    if (!(await hasPermissions(...requiredPermissions))) {
        if (await hasPermissions(PERMISSIONS.CUSTOMER))
            window.location = CUSTOMER_REDIRECT_URL;
        else
            window.location = GENERIC_REDIRECT_URL;
        // Hang until the page finishes redirecting the user. Otherwise, more code may execute.
        await new Promise((resolve) => {});
    }
}

/**
 * Checks whether the currently logged in user has the given permissions. Returns true if the user has all given permissions, false otherwise.
 * @param {Array<Permission>} permissions - The list of permissions checked against the user's custom claims.
 * @returns {Promise<boolean>}
 */
export async function hasPermissions(...permissions) {
    let customUserClaims = await getAuthClaims();
    let hasAllPermissions = true;
    for (let permission of permissions) {
        hasAllPermissions &&= customUserClaims[permission] ?? false;
    }
    return hasAllPermissions;
}